<template>
  <div class="indicator" :class="{ 'is-animating': animate, 'indicator--nav': inNavigationBar }">
    <div class="indicator__number">{{ count }}</div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';

interface IndicatorProps {
  inNavigationBar?: boolean;
}

withDefaults(defineProps<IndicatorProps>(), {
  inNavigationBar: false,
});

const animate = ref(false);
const store = useBasketStore();
const { count } = storeToRefs(store);

watch(count, (newCount, oldCount) => {
  if (newCount > oldCount) {
    animate.value = true;

    setTimeout(() => {
      animate.value = false;
    }, 500);
  }
});
</script>

<style lang="scss" scoped>
.indicator {
  min-width: px(16);
  height: px(16);
  border-radius: px(8);
  background-color: $c-orange-1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: px(4);

  &--nav {
    position: absolute;
    bottom: 0;
    left: 50%;
  }

  &.is-animating {
    animation: bounce 0.5s ease-in-out;
  }

  &__number {
    @include base-headings-font;
    font-size: px(10);
    color: $c-white;
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}
</style>
